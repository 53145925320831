import { BlogPostDisplay } from '@/layout/BlogPostDisplay';
import { Seo } from '@/layout/Seo';
import { Pagination } from '@/Pagination';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const PostsContainer = styled.div`
    padding: 32px 16px 1px;
    max-width: 1364px;
    margin: 0 auto;

    @media (min-width: 1024px) {
        padding: 64px 32px 32px;
    }
`;

const DesktopGrid = styled.div<{ single: boolean }>`
    ${({ single }) =>
        !single &&
        css`
            @media (min-width: 1280px) {
                display: grid;
                grid-template-columns: 50.66% 1fr;
                grid-template-rows: repeat(3, 1fr);
                gap: 16px;
                margin-bottom: 64px;
            }
        `};
`;

type BlogPageProps = {
    data: {
        allMdx: {
            nodes: {
                frontmatter: {
                    title: string;
                    date: string;
                    cover: { childImageSharp: ImageDataLike };
                };
                excerpt: string;
                fields: {
                    slug: string;
                };
            }[];
        };
    };
    pageContext: {
        currentPage: number;
        limit: number;
        numPages: number;
        skip: number;
    };
};

const BlogPage = ({ data, pageContext }: BlogPageProps) => {
    const { currentPage, numPages } = pageContext;

    const allBlogPosts = data.allMdx.nodes;

    const singleDisplay = allBlogPosts.length < 4;

    return (
        <Fragment>
            <Seo
                title="Our Blog - Simhairy Dental"
                description="Our blog offers wide range of dental care knowledge and best practices in the field of dentistry."
            />

            <PostsContainer>
                <DesktopGrid single={singleDisplay}>
                    {allBlogPosts.slice(0, 4).map((post, i) => (
                        <BlogPostDisplay
                            key={i}
                            cover={post.frontmatter.cover.childImageSharp}
                            title={post.frontmatter.title}
                            slug={`/blog${post.fields.slug}/`}
                            date={post.frontmatter.date}
                            excerpt={post.excerpt}
                            firstPost={singleDisplay ? false : i === 0}
                            desktopGrid={singleDisplay ? false : i !== 0}
                        />
                    ))}
                </DesktopGrid>
                {allBlogPosts.slice(4).map((post, i) => (
                    <BlogPostDisplay
                        key={i}
                        cover={post.frontmatter.cover.childImageSharp}
                        title={post.frontmatter.title}
                        slug={`/blog${post.fields.slug}/`}
                        date={post.frontmatter.date}
                        excerpt={post.excerpt}
                    />
                ))}
            </PostsContainer>
            {numPages > 1 ? <Pagination currentPage={currentPage} numPages={numPages} /> : ''}
        </Fragment>
    );
};

export default BlogPage;

export const query = graphql`
    query BlogPage($limit: Int!, $skip: Int!) {
        allMdx(
            filter: { fileAbsolutePath: { regex: "/blog/" } }
            sort: { fields: frontmatter___date, order: DESC }
            limit: $limit
            skip: $skip
        ) {
            nodes {
                fields {
                    slug
                }
                excerpt(pruneLength: 123)
                frontmatter {
                    title
                    date(formatString: "DD MMMM YYYY")
                    cover {
                        childImageSharp {
                            gatsbyImageData(width: 712, height: 456)
                        }
                    }
                }
            }
        }
    }
`;
